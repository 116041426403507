const initialState = {
  login_status: false,
  login_token: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "login_user":
      return {...state,login_status: true,};

    case "logout_user":
      return {...state,login_status: false,};

    case "set_login_token":
      return {...state,login_token: action.payload,};

    default:
      return {...state};
  }
};

export default loginReducer;