import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import loginReducer from "./Login/reducers";

const reducer = combineReducers({
  login: loginReducer,
});

const composeEnhancers = composeWithDevTools({
  realtime: true,
  name: "Shake",
  hostname: "localhost",
  port: 3000, // the port your remotedev server is running at
});

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
export default store;