import { React, useEffect, useState} from "react";
import './stylesheets/global.css'
import { useSelector, useDispatch } from "react-redux";
import { HashRouter, Routes, Route } from "react-router-dom";

import {
  ToolsHome
} from "./pages/"
import $ from "jquery";

let pagehash = '';

function AppBackend() {

  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => {  
    window.addEventListener("hashchange",
    function(){ 
      if(pagehash !== window.location.hash) {
        
      }
    }, false);
    window.onpopstate = e => {
      if(pagehash !== window.location.hash) {
        
      }
    }
  }, []);

  /* Check For Changes in Signup Loading */
  useEffect(() => {
    if (window !== undefined) {
      
    }
  });

  return (
    <div className="App">
      <HashRouter basename="/">
        <Routes>
          <Route path="/" 
            element={<ToolsHome />} 
          />
          <Route path="*" 
            element={<ToolsHome />} 
          />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default AppBackend;